import TopBar from "../Components/TopBar";
import Footer from "../Components/Footer";
import { MdEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";

import president from "../assets/img/president_img.jpg";
import vp1 from "../assets/img/vp1_img.jpg";
import vp2 from "../assets/img/vp2_img.jpg";
import vp3 from "../assets/img/vp3_img.jpg";
// import secGen from "../assets/img/gen_sec_img.jpg";
// import finSec from "../assets/img/fin_sec_img.jpg";
import welfare from "../assets/img/welfare_img.jpg";
// import pro from "../assets/img/pro_img.jpg";
// import legal from "../assets/img/legal_img.jpg";
import auditor from "../assets/img/auditor_img.jpg";
import assSec from "../assets/img/ass_sec_gen_img.jpg";
import anon from "../assets/img/anon_img.png";
import Hero from "../Components/Section_hero";

import pres_img from "../assets/img/president_2025.jpg";
import vp1_img from "../assets/img/vp1_2025.jpg";
import vp2_img from "../assets/img/vp2_2025.jpg";
import vp3_img from "../assets/img/vp3_2025.jpg";
import ass_sec_img from "../assets/img/ass_sec_gen_2025.jpg";
import sec_gen_img from "../assets/img/sec-gen_2025.jpg";
import welfare_img from "../assets/img/welfare_2025.jpg";
import fin_sec_img from "../assets/img/fin_sec_2025.jpg";
import pro_img from "../assets/img/pro_2025.jpg";
import legal_img from "../assets/img/legal_2025.jpg";
import treasure_img from "../assets/img/treasure_2025.jpg";

const excos = [
  {
    position: "President",
    name: "Timothy Atasie",
    image: pres_img,
    email: "president@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/timothy-d-atasie-58370a146?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    about: `Timothy Atiase is an energy lawyer at Odujinrin & Adefulu, specializing in advising International Oil Companies (IOCs) on policy and transactional matters in the energy sector. With a strong legal background and a problem-solving mindset, he focuses on key challenges in energy, sustainability, and natural resources.

He holds an LLM from Afe Babalola University and is currently pursuing a doctorate, researching the legal implications of decarbonization and energy transition in Nigeria.
`,
  },
  {
    position: "Vice President 1",
    name: "Funmi Waleola",
    image: vp1_img,
    email: "vp-1@alumni.abuad.edu.ng",
    linkedin: "",
    about: `Nsikak is an Emergency Medicine Resident Physician in a prestigious U.S. program, with a proven track record of leadership both within and outside ABUAD.

A dedicated advocate for underrepresented voices, she is committed to advancing member engagement, inclusivity, and professional development within the ABUAD Alumni Association.`,
  },
  {
    position: "Vice President 2",
    name: "Nsikak",
    image: vp2_img,
    email: "vp-2@alumni.abuad.edu.ng",
    linkedin: "",
    about: `Nsikak is an Emergency Medicine Resident Physician in a prestigious U.S. program, with a proven track record of leadership both within and outside ABUAD.

A dedicated advocate for underrepresented voices, she is committed to advancing member engagement, inclusivity, and professional development within the ABUAD Alumni Association.`,
  },
  {
    position: "Vice President 3",
    name: "Ihaza Blessing",
    image: vp3_img,
    email: "vp-3@alumni.abuad.edu.ng",
    linkedin: "",
    about: `Ihaza Blessing is a highly accomplished double alumna of Afe Babalola University with an impressive educational background in Human Anatomy.

She earned her Bachelor of Science and Master of Science degrees with distinction in Human Anatomy. Passionate about research, Blessing specializes in neuroendocrinology and aims to pursue advanced training in the field.

Beyond academics, her versatility and commitment to personal growth shine through her love for reading and dedication to community service.`,
  },
  {
    position: "Secretary-General",
    name: "Femi Ishola",
    image: sec_gen_img,
    email: "gen-sec@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/femi-ishola-b77314131?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    about: `Ishola Mujeeb Oluwafemi is an innovative advocate with vast experience in core litigation. He has a keen interest in the development of Nigerian law and the interplay between law, policy, and society.

A senior associate at Dele Adesina LP, Lagos, he also leads the Banking Law team. His wealth of knowledge, confidence, and integrity have made him a key figure in alumni affairs. Having served in several capacities within the association, his dedication led to his election as Assistant Secretary-General in 2022 and now as Secretary-General.
`,
  },
  {
    position: "Assistant Secretary General",
    name: " Enovwo Blessing",
    image: ass_sec_img,
    email: "assistant-gen-sec@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/enovwo-blessing-mukorho-96b444185?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    about: `Blessing Mukorho is a lawyer with extensive experience in media, advertising, and project management.

She currently serves as the Quality Manager and Leadership Team Secretary at RAGE Media Group, a leading advertising agency in Abuja.

Renowned for her leadership and organizational excellence, Blessing is passionate about creating sustainable, innovative solutions for improved alumni engagement and service. She loves traveling and exploring places to enhance cultural confectionery and culinary experiences.
`,
  },
  {
    position: " Welfare Officer",
    name: "Lukman Oshioke",
    image: welfare_img,
    email: "welfare@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/lukman-oshioke-632054344?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    about: `Lukman Oshioke is a driven and multifaceted individual, balancing Ph.D. studies with professional endeavors. As a Graduate Assistant and Entrepreneur, he successfully manages a thriving construction and logistics company.

With exceptional organizational skills and business acumen, Lukman is dedicated to fostering strong relationships with colleagues, clients, and peers. His work ethic, passion for excellence, and unique blend of academic rigor and business savvy position him to make a lasting impact in his field.
`,
  },
  {
    position: "Financial Secretary",
    name: "Mark Adah",
    image: fin_sec_img,
    email: "financial-sec@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/lukman-oshioke-632054344?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    about: `Lukman Oshioke is a driven and multifaceted individual, balancing Ph.D. studies with professional endeavors. As a Graduate Assistant and Entrepreneur, he successfully manages a thriving construction and logistics company.

With exceptional organizational skills and business acumen, Lukman is dedicated to fostering strong relationships with colleagues, clients, and peers. His work ethic, passion for excellence, and unique blend of academic rigor and business savvy position him to make a lasting impact in his field.
`,
  },
  {
    position: "Public Relations Officer",
    name: "Oladeji Emmanuel Oluwatobiloba",
    image: pro_img,
    email: "pro@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/emmanuel-oladeji-aicmc-b37503225?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    about: `Oladeji Emmanuel Oluwatobiloba is a seasoned travel expert with a passion for connecting people to global opportunities. Having served as SRC PRO (2018-2020) and Social and Management Student Association PRO (2019-2020), he honed strong communication and organizational skills.

Now a Travel Consultant, Tobi specializes in study abroad programs, visa processing, and flight bookings, helping individuals navigate their journey toward international education and travel.
`,
  },
  {
    position: "Legal Adviserr",
    name: "Toluwani Alabi",
    image: legal_img,
    email: "legal@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/alabi-toluwani-2536a022a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    about: `A dedicated legal practitioner with expertise in Energy & Natural Resources Law, Arbitration, and Human Rights, Toluwani brings a wealth of experience to our alumni community. Currently serving as a State Counsel at the Ekiti State Ministry of Justice, he is committed to driving impactful legal strategies for our association.
`,
  },
  {
    position: "Treasurer",
    name: "Adams Tolulope",
    image: treasure_img,
    email: "treasurer@alumni.abuad.edu.ng",
    linkedin:
      "https://www.linkedin.com/in/alabi-toluwani-2536a022a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    about: `A proud graduate of Accounting (Class of 2016), Tolulope has always been passionate about leadership and making a positive impact. She previously served as the Departmental President (NUASA) and Faculty President (SAMSA), showcasing her bold, confident, and result-driven approach.

With her expertise in financial management and unwavering commitment to service, she is set to ensure transparency and accountability in our alumni affairs.`,
  },
];

function About() {
  return (
    <div className="bg-gray-50">
      <TopBar />
      <Hero title={"We are connecting all ABUAD graduates"} />

      <div className="py-8 px-4 space-y-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-y-6">
          {excos.map((exco, index) => {
            return (
              <div className="text-center text-gray-700">
                <img
                  className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                  src={exco.image}
                  alt="abuad alumni executive"
                ></img>
                <p className="text-sm">{exco.name}</p>
                <p className="text-xs text-primary">{exco.position}</p>
                <p className="text-xs text-primary">{exco.email}</p>
                <div className="flex space-x-2 text-base justify-center">
                  <span>
                    <MdEmail />
                  </span>
                  <span>
                    <FaLinkedin />
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
